'use strict'

var angular = require(21)
var creditcards = require(30)
var number = require(7)
var cvc = require(4)
var expiration = require(5)

module.exports = angular
  .module('credit-cards', [])
  .value('creditcards', creditcards)
  .directive('ccNumber', number)
  .directive('ccExp', expiration)
  .directive('ccExpMonth', expiration.month)
  .directive('ccExpYear', expiration.year)
  .directive('ccCvc', cvc)
  .name
