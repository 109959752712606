'use strict'

var ccTypes = require(27)
var camel = require(25)
var extend = require(55)

module.exports = extend(ccTypes, {
  get: function getTypeByName (name) {
    return ccTypes.types[camel(name)]
  }
})
