'use strict'

var parseIntStrict = require(48)
var expandYear = require(36)

module.exports = function parseYear (year, expand, now) {
  year = parseIntStrict(year)
  if (year == null) return
  if (!expand) return year
  return expandYear(year, now)
}
